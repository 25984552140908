.nav-bar {
  background: #264653;
  width: 100%;
  height: 75px;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 0;

  .hamburger {
    position: absolute;
    top: 15px;
    right: 0;
    padding-right: 30px;
    width: auto;
    display: flex;
    justify-content: flex-end;
  }

  .logo {
    display: block;
    padding: 8px 0;

    img {
      margin: 8px auto;
      width: 35px;
      height: auto;
      margin-left: 35px;

      &.sub-logo {
        width: 50px;
        display: block;
        top: 50px;
        left: -6px;
        position: absolute;
      }
    }
  }

  nav {
    display: block;
    // text-align: center;
    position: relative;
    height: 75px;
    // top: 50%;
    // margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #264653;
      display: inline-block;
      // margin-left: 20px;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #e76f51;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        padding-left: 30px;
        padding-right: 30px;
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      padding-left: 30px;
      padding-right: 30px;

      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      padding-left: 30px;
      padding-right: 30px;

      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
      padding-left: 30px;
      padding-right: 30px;

      &:after {
        content: 'PORTFOLIO';
      }
    }

    a.linkedin-link {
      padding-left: 30px;
      padding-right: 30px;

      &:after {
        content: 'LINKED-IN';
      }
    }

    a.github-link {
      padding-left: 30px;
      padding-right: 30px;

      &:after {
        content: 'GITHUB';
      }
    }

    a.active {
      svg {
        color: #e76f51;
      }
    }
  }

  ul {
    position: relative;
    // bottom: 20px;
    width: 100%;
    display: inline-block;
    padding: 3;
    list-style: none;
    // text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: inline-block;
        font-size: 15px;
        line-height: 16px;
        color: #264653;

        &:hover {
          color: #e76f51;
        }
      }
    }
  }
}

.miniNavBar {
  background-color: #264653;
  width: 100%;
  height: 100px;
  text-align: center;
  animation: fadeIn 0.2s 0s;
}

@media screen and (min-width: 500px) {
  .miniNavBar {
    height: 50px;
  }
}

@media screen and (min-width: 800px) {
  .nav-bar {
    width: 60px;
    height: 100%;
    min-height: 500px;

    .logo {
      img {
        display: block;
        margin: 8px auto;
        width: 35px;
        height: auto;
        // margin-left: 10px;
        position: relative;

        &.sub-logo {
          width: 50px;
          display: block;
          top: 0;
          left: 0;
          position: relative;
        }
      }
    }

    nav {
      display: block;
      text-align: center;
      position: absolute;
      height: 210px;
      top: 50%;
      margin-top: -120px;
      width: 100%;

      a {
        font-size: 22px;
        color: #264653;
        display: block;
        margin-left: 0px;

        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;

        i {
          transition: all 0.3s ease-out;
        }

        &:hover {
          color: #e76f51;

          svg {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

        &:after {
          content: '';
          font-size: 9px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }

        &:first-child {
          padding-left: 0px;
          padding-right: 0px;
          &:after {
            content: 'HOME';
          }
        }
      }

      a.about-link {
        padding-left: 0px;
        padding-right: 0px;
        &:after {
          content: 'ABOUT';
          text-align: center;
        }
      }

      a.contact-link {
        padding-left: 0px;
        padding-right: 0px;
        &:after {
          content: 'CONTACT';
        }
      }

      a.portfolio-link {
        padding-left: 0px;
        padding-right: 0px;

        &:after {
          content: 'PORTFOLIO';
        }
      }

      a.linkedin-link {
        display: none;
      }

      a.github-link {
        display: none;
      }

      a.active {
        svg {
          color: #e76f51;
        }
      }
    }

    ul {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: block;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;

      li {
        a {
          padding: 7px 0;
          display: block;
          font-size: 15px;
          line-height: 16px;
          color: #264653;

          &:hover {
            color: #e76f51;
          }
        }
      }
    }
  }
}

.contact-form {
  width: 100%;
  margin-top: 20px;
  margin-top: 50px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #264653;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 5px;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #264653;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
    border-radius: 5px;
  }

  .flat-button {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #264653;
    background: #264653;
    border-radius: 5px;
    margin-top: 25px;
    float: right;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #e76f51;
      color: #fff;
      border: 1px solid #e76f51;
    }
  }
}

.map-wrap {
  background: rgba(8, 253, 216, 0.1);
  // float: right;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  max-height: 325px;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

.info-map {
  position: absolute;
  background: #2a9d8f;
  top: 60px;
  right: 3vw;
  // margin-left: 5px;
  z-index: 999999;
  width: 175px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;
  height: auto;
  text-align: right;

  span {
    font-size: 16px;

    span {
      color: #e76f51;
    }
  }
}

@media screen and (min-width: 800px) {
  .texty {
    margin-top: 50px;
  }

  .contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }

      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #264653;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      border-radius: 5px;
    }

    textarea {
      width: 100%;
      border: 0;
      background: #264653;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
      border-radius: 5px;
    }

    // .flat-button {
    //   color: #264653;
    //   font-size: 11px;
    //   letter-spacing: 3px;
    //   text-decoration: none;
    //   padding: 8px 10px;
    //   border: 1px solid #264653;
    //   float: left;
    //   border-radius: 4px;
    //   background: 0 0;
    //   text-transform: uppercase;
    //   float: right;
    //   text-align: center;
    //   margin-right: 10px;
    // }
    .flat-button {
      color: #fff;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #264653;
      background: #264653;
      border-radius: 5px;
      margin-top: 25px;
      float: right;
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;

      &:hover {
        background: #e76f51;
        color: #fff;
        border: 1px solid #e76f51;
      }
    }
  }

  .map-wrap {
    background: rgba(8, 253, 216, 0.1);
    float: right;
    width: 53%;
    height: 115%;
    margin-top: -3%;
    max-height: none;
  }

  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }

  .info-map {
    position: absolute;
    background: #2a9d8f;
    top: 50px;
    right: 3vw;
    z-index: 999999;
    width: 175px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    span {
      font-size: 16px;

      span {
        color: #ffd700;
      }
    }
  }
}
